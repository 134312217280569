import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  install(Vue) {
    Vue.prototype.getPdf = function (name) {

      html2Canvas(document.querySelector('#pdfDom'), {
        allowTaint: true
      }).then(function (canvas) {
        let PDF = new JsPDF('', 'pt', 'a4')
        // 获取长宽
        let contentWidth = canvas.width
        let contentHeight = canvas.height

        //根据pdf a4 尺寸获取适当的高
        let pageHeight = contentWidth / 592.28 * 841.89

        //剩余高度
        let leftHeight = contentHeight

        //定位每页开始的位置
        let position = 0

        //图片在PDF里的宽度
        let imgWidth = 595.28

        let imgHeight = 592.28 / contentWidth * contentHeight

        let pageData = canvas.toDataURL('image/jpeg', 1.0)

        //如果只有一页的高度
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          //高度很多，循环截取
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save(name + '.pdf')
      }
      )
    }
  }
}